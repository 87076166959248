body {
  margin: 20px !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.flex {
  display: flex;
}

.center {
  width: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.header {
  display: flex;
}

.header-title {
  font-size: 5vw;
  margin-top: 2rem;
}

.logo-wrapper {
  width: 20%;
  text-align: center;
}

.logo-wrapper:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.main-logo {
  width: 100%;
  height: auto;
  vertical-align: middle;
}

.content {
  width: 100%;
  background-color: #4d9dfc;
  margin-top: 20px;
  border-radius: 8px;
  padding: 10px 24px 10px 24px;
  min-height: 65vh;
}

.content-title {
  font-size: 3.5vh;
  margin: 0;
  color: white;
}

.submit-btn {
  margin-top: 40px;
  height: 60px;
  width: 100%;
  font-size: 2rem;
}

.mantine-Input-input {
  font-size: 2vh;
  height: 100%;
}

.dark-light-mode {
  position: absolute;
  top: 20px;
  right: 20px;
}

li::marker {
  font-size: 3vh;
}

.player-section {
  min-height: 375px;
  height: 40vh;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 0 0 20px 0;
}

.button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.add-player {
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
}

.mantine-InputWrapper-root {
  flex-grow: 1;
}


@media only screen and (max-width: 640px) {
  .header {
    flex-direction: column;
  }

  .header-title {
     margin-top: 1rem;
     margin-bottom: 0.5rem;
     font-size: 7vw;
  }

  .logo-wrapper {
    width: 40%;
  }
  
  .player-section {
    display: block;
  }

  .mantine-Button-root, 
  .add-player, 
  .mantine-InputWrapper-root {
    width: 100% !important;
  }

  .mantine-InputWrapper-root {
    padding-right: 55px;
  }

  .player-section {
    min-height: auto;
  }
}

@media only screen and (max-width: 980px) {
  .player-section {
    flex-wrap: nowrap;
    height: auto;
  }
}

@media only screen and (max-width: 370px) {
  .add-player span {
    font-size: 15px !important;
  }
}
